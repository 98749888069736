export default {
  
  /**
   * 下载文件
   * @param {*} param0 
   */
  downloadFile({
    fileName = "download",
    type = "image/png",
    src = "",
    content = ""
  }) {
    // 使用路径下载图片
    if (src) {
      let canvas = document.createElement("canvas");
      let img = document.createElement("img");
      img.onload = function() {
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.getContext("2d").drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob((blob) => {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
          URL.revokeObjectURL(link.href); // 释放URL 对象
        }, type);
      };
      img.setAttribute("crossOrigin", "Anonymous");
      img.src = src;
    }

    // 使用二进制下载文件
    if (content) {
      const blob = new Blob([content], {type});
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    }    
  }
}