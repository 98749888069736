/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-10-08 17:42:42
 * @LastEditTime: 2019-11-05 16:08:12
 * @LastEditors: Please set LastEditors
 */
let SERVER_URLS = {
  "ioc-h.icloudcity.com": "api-ioc-h.icloudcity.com", // 联创
  "ioc-u.icloudcity.com": "api-ioc-h.icloudcity.com", // UAT
  "ioc-beta.icloudcity.com": "api-ioc.icloudcity.com", // BETA
  "ioc.icloudcity.com": "api-ioc.icloudcity.com", // 生产
  "ioc.gzurbanspace.com":"api-ioc.gzurbanspace.com" // 设计之都
  
};

let protocol = window.location.protocol + "//";
let host = window.location.host;
let hostname = window.location.hostname;

let BASEURL = SERVER_URLS[host] ? (protocol + SERVER_URLS[host]) : (protocol + host);
let WEBSOCKET_URL = hostname === "localhost" ? "ws://10.0.145.84:9099" : `${BASEURL.replace(/http/, "ws")}`;

let sysRegistry = {
  WEBSOCKET_URL,
  SAFETYMGMT: "/ioc/safetymgmt", // 安防
  ENTERPRISE: "/ioc/enterprise", // 企业服务
  ORDER: "/ioc/order", // 付费方式
  ADMINMGMT: "/ioc/personmgmt", // 人员管理微服务
  FILEMGMT: "/ioc/filemgmt", // 文件上传微服务
  VEHICLEMGMT: "/ioc/vehiclemgmt", // 车辆管理微服务
  WEBSOCKET: "/ioc/websocket", // 长连接微服务
  BASEMGMT: "/ioc/atomiccommon", // 基础数据微服务
  BASECOMMON: "/ioc/atomiccommon", // 人员详情9171
  HOMELINK: "/ioc/homelink",
  BASEQUIPMENT: "/ioc/atomicequipment", // 设备服务9172
  BASEPERSON: "/ioc/atomicperson", // 人员列表数据
  BASEVEHICLE: "/ioc/atomicvehicle", // 车辆数据9174
  EQUIPMENTMGMT: "/ioc/equipmentmgmt", // 设备管理微服务9097
  REPORT: "/ioc/report", // 报表管理微服务9098
  SYSROLES: "/ioc/security", // 权限管理微服务9093
  ATOMICENTERPRISE: "/ioc/atomicenterprise", // 公司管理微服务9177
  MESSAGE: "/message",
  SECURITY: "/security",
  INVESTMENT: "/investment",
  GATEWAY: "/ioc/application", // 媒体网关配置微服务8765
  ENERGY: "/ioc/newbasemgmt", // 绿色能源微服务9191
  MAINTAIN: "/ioc/maintain", // 环境配置
  REPAIR: "/ioc/repair", // 报修模块微服务9199
  EQUIPMENT: "/ioc/vanke-ioc-atomic-equipment", // 设施基础数据微服务
  DATACENTERSELFSERVICE: "/datacenterselfservice", // 数据中台自助服务
  DATACENTER: "/datacenter/api/ibp", // 新数据中台自助服务
  VIDEO: "/ioc/safetymgmt/videoforward", // 公有云视频
  PARK: "/ioc/maintain", // 获取园区视频IP
  DATACENTERAPI: "/datacenter",
  DATACENTERFORMS: "/datacenterforms",
  PROPERTY: "/ioc/property",
  GUEST: "/ioc/guest",
  SMARTBUSINESS: "/ioc/vcommercemgmt" // 智慧商业
};

// 智能客服
let itaiPort = location.protocol.slice(0, -1) === "http" ? "83" : "443";
let itaiHostname = window.GLOBAL_CONFIG.isPro === "1"
  ? "itai.vanke.com"
  : "t-itai.vanke.com";
let itaiPath = window.GLOBAL_CONFIG.isPro === "1"
  ? "/itsm-plugin.php?sid=53"
  : "/itsm-plugin.php?sid=54";

let itaiURL = `//${itaiHostname}:${itaiPort}${itaiPath}`;

// 云城APP后台
let iotPrdURL = {
  uatURL: "https://api.icloudcity.com",
  curURL: "https://icloudcity.com",
  apiURL: "https://api.icloudcity.com"
};
let iotUatURL = {
  uatURL: "http://icloudcityuat.vanke.com",
  curURL: "http://icloudcityuat.vanke.com",
  apiURL: "http://icloudcityuat.vanke.com"
};
let iotDevURL = {
  uatURL: "http://icloudcityuat.vanke.com",
  curURL: "http://10.0.145.153",
  apiURL: "http://10.0.145.153"
};
let iotAdminURLS = {
  "ioc.icloudcity.com": iotPrdURL,
  "ioc-beta.icloudcity.com": iotPrdURL,
  "ioc-u.icloudcity.com": iotUatURL,
  "ioc-h.icloudcity.com": iotUatURL,
  "172.31.202.14": iotUatURL
};
let iotAdminURL = iotAdminURLS[hostname] || iotDevURL;
let smartToiletURL = "http://vstoiletadmin.wstro.com";

let layerCode = [
  // 顺序不要改动
  {
    iocEquipTypeLv1Code: "BCSS", // 摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 烟感
    iocEquipTypeLv2Code: "_BCSS08",
    iocEquipTypeLv3Code: "_020"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 门禁
    iocEquipTypeLv2Code: "_BCSS06",
    iocEquipTypeLv3Code: "_006"
  }
];
let cameraCode = [
  {
    iocEquipTypeLv1Code: "BCSS", // 摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 人脸摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_001",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 车辆摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_002",
    equipStatusCode: "在线"
  },
  {
    iocEquipTypeLv1Code: "BCSS", // 高清摄像头
    iocEquipTypeLv2Code: "_BCSS04",
    iocEquipTypeLv3Code: "_006",
    iocEquipTypeLv4Code: "_003",
    equipStatusCode: "在线"
  }
];

export { BASEURL, sysRegistry, itaiURL, iotAdminURL, smartToiletURL, layerCode, cameraCode };
