import commonApi from "./commonApi"
import request from "./request";
import crypto from "./cryptoApi";
import {
  cosPutObject,
  obsPutObject,
  cosGetObjectUrl,
  obsGetObjectUrl,
  cosGetObject,
  cosDeleteObject,
  obsDeleteObject
} from "./cloudStorage.js";

// 当前云环境
let cloudEnv = "cos";
// 存放当前业务分类，用来获取对应模块的桶名
let moduleType = "";
// cos 参数
let cos = null;
//  obs 参数
let obs = null;
let region = "";
// 上传文件对应的桶名
let upLoadBucketName = "";
let StorageClass = "STANDARD";



/**
 * 获取鉴权数据
 * @param {Object} businessType 业务分类，用来获取对应模块的桶名 访客: guest, 能效: energy
 * @param {Function} parkCode 当前园区的parkCode， 用来区分 COS，OBS，新的数据必传
 */
function getObsMeg({
  businessType = "",
  parkCode = JSON.parse(sessionStorage.getItem("hearCurrentPosCode")).posCode
}) {
  let timeStamp = new Date().getTime();
  let timeWebsession = crypto.encrypt(timeStamp, "UE8ES136TFWGTEF9");
  sessionStorage.setItem("OBS_TOKEN", timeWebsession);
  let params = {
    businessType,
    park: parkCode || "",
    time: timeStamp
  };
  return request({
    url: "/ioc/filemgmt/objectstorage/info/v1.0",
    method: "get",
    args: params
  }).then(res => {
    if (res.code === 100000) {
      cloudEnv = crypto.decrypt(res.data.osFirm) || "obs";
      upLoadBucketName = res.data.bucketName ?
        crypto.decrypt(res.data.bucketName) :
        "";
      if (cloudEnv === "obs") {
        // eslint-disable-next-line
        obs = new ObsClient({
          access_key_id: crypto.decrypt(res.data.ak),
          secret_access_key: crypto.decrypt(res.data.sk),
          server: crypto.decrypt(res.data.endPoint)
        });
      } else {
        // eslint-disable-next-line
        cos = new COS({
          SecretId: crypto.decrypt(res.data.ak),
          SecretKey: crypto.decrypt(res.data.sk)
        });
        region = crypto.decrypt(res.data.endPoint);
        // region ='ap-guangzhou';
      }
    } else {
      throw new Error(res.message);
    }
  }).catch(() => {
    throw new Error("获取鉴权失败！");
  });
}

/**
 * 上传文件
 * @param {Object} file 需要上传的文件
 * @param {Function} ProgressCallback(speed, percent)
 * @param {String} businessType 业务分类，用来获取对应模块的桶名 访客: guest, 能效: energy
 * @param {String} parkCode 园区编码
 */
async function obsUpload({
  businessType,
  file,
  parkCode = JSON.parse(sessionStorage.getItem("hearCurrentPosCode")).posCode,
  ProgressCallback
}) {
  if ((!cos && !obs) || moduleType !== businessType) {
    try {
      await getObsMeg({
        businessType,
        parkCode
      });
    } catch (err) {
      throw new Error("获取鉴权失败！");
    }
  }
  // 创建文件名称
  let n = file.name ? file.name.lastIndexOf(".") : "-1";
  let fileName =
    n > -1 ?
    new Date().getTime().toString() + file.name.substr(n) :
    new Date().getTime().toString();
  return new Promise((resolve, reject) => {
    if (cloudEnv === "cos") {
      cosPutObject({
        cos,
        file,
        region,
        fileName,
        StorageClass,
        ProgressCallback,
        bucketName: upLoadBucketName
      }, (result) => {
        if (result.status) {
          resolve({
            fileName,
            bucketName: upLoadBucketName,
            status: result.data.statusCode
          });
        } else {
          reject(Error("上传文件失败！"));
        }
      });
    } else {
      obsPutObject({
        obs,
        fileName,
        file,
        ProgressCallback,
        bucketName: upLoadBucketName
      }, (result) => {
        if (result.status) {
          resolve({
            fileName,
            bucketName: upLoadBucketName,
            status: result.data.CommonMsg.Status
          });
        } else {
          reject(Error("上传文件失败！"));
        }
      });
    }
  });
}

/**
 * 获取文件下载地址
 * @param {String} bucketName 文件对应的桶名
 * @param {String} saveType 需要获取文件的类型 默认为 file， file : 可以获取文件的临时地址，arraybuffer：二进制数据
 * @param {String} fileName 对象名称
 * @param {String} parkCode 园区编码
 * 兼容现有使用情况  传了 saveType, parkCode取传入的parkCode， 没有传saveType 只传了parkCode，那么saveType取默认值file，parkCode 取第三个参数
 */
async function obsDownload(fileName, bucketName, saveType = "file", parkCode = JSON.parse(sessionStorage.getItem("hearCurrentPosCode")).posCode) {
  if (arguments[2] !== "file" && arguments[2] !== "arraybuffer") {
    saveType = "file";
    parkCode = arguments[2];
  }
  if (!cos && !obs) {
    try {
      await getObsMeg({
        parkCode
      });
    } catch (err) {
      throw new Error("获取鉴权失败！");
    }
  }
  return new Promise((resolve, reject) => {
    if (cloudEnv === "cos") {
      if (saveType === "file") {
        // 文件临时URL地址
        cosGetObjectUrl({
          cos,
          region,
          fileName,
          bucketName
        }, (result) => {
          if (result.status) {
            resolve(result.data);
          } else {
            reject(Error("获取文件失败！"));
          }
        });
      } else {
        // 文件内容，字符串格式
        cosGetObject({
          cos,
          region,
          fileName,
          bucketName
        }, (result) => {
          if (result.status) {
            resolve(result.data);
          } else {
            reject(Error("获取文件失败！"));
          }
        });
      }
    } else {
      obsGetObjectUrl({
        obs,
        bucketName,
        fileName,
        saveType
      }, (result) => {
        if (result.status) {
          resolve(result.data);
        } else {
          reject(Error("获取文件失败！"));
        }
      });
    }
  });
}

/**
 * 删除文件 删除暂时没有开通权限
 * @param {String} bucketName 文件对应的桶名
 * @param {String} fileName 对象名称
 * @param {String} parkCode 园区编码
 */
async function obsDelete(fileName, bucketName, parkCode = JSON.parse(sessionStorage.getItem("hearCurrentPosCode")).posCode) {
  if (!cos && !obs) {
    try {
      await getObsMeg({
        parkCode
      });
    } catch (err) {
      throw new Error("获取鉴权失败！");
    }
  }
  return new Promise((resolve, reject) => {
    if (cloudEnv === "cos") {
      cosDeleteObject({
        cos,
        region,
        fileName,
        bucketName
      }, (result) => {
        if (result.status) {
          resolve(result.data);
        } else {
          reject(Error("删除失败！"));
        }
      });
    } else {
      obsDeleteObject({
        obs,
        bucketName,
        fileName
      }, (result) => {
        if (result.status) {
          resolve(result.data);
        } else {
          reject(Error("删除失败！"));
        }
      });
    }
  });
}

/**
 * 使用临时URL下载文件, obs 使用二进制数据下载， cos使用在线链接强制下载
 * @param {String} fileName 文件名
 * @param {String} bucketName 桶名
 * @param {String} parkCode 园区编码
 */
async function obsDownloadFile(fileName, bucketName = "buckettest", parkCode = JSON.parse(sessionStorage.getItem("hearCurrentPosCode")).posCode) {
  if (!cos && !obs) {
    try {
      await getObsMeg({
        parkCode
      });
    } catch (err) {
      throw new Error("获取鉴权失败！");
    }
  }
  if (cloudEnv === "obs") {
    const content = await obsDownload(fileName, bucketName, "arraybuffer");
    commonApi.downloadFile({
      fileName,
      content
    });
  } else {
    let url = await obsDownload(fileName, bucketName, "file", parkCode);
    url = url + (url.indexOf("?") > -1 ? "&" : "?") + "response-content-disposition=attachment"; // 补充强制下载的参数
    let elink = document.createElement("a");
    elink.download = fileName;
    elink.style.display = "none";
    elink.href = url;
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
  }
}

// 将base64转换为文件对象
function base64toFile(dataurl, filename) {
  var arr = dataurl.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  // 转换成file对象
  return new File([u8arr], filename, {
    type: mime
  });
  // 转换成成blob对象
  // return new Blob([u8arr],{type:mime});
}

export default {
  obsUpload,
  obsDownload,
  obsDelete,
  obsDownloadFile,
  base64toFile,
  getObsMeg
};
