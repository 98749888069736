import './less/app.less';
import * as singleSpa from 'single-spa';
import "./js/global";

// 打上gitcommit信息
if (!window.$WANRUI) {
  window.$WANRUI = {}
}
window.$WANRUI.IOCROOTHTML_GITCOMMIT = process.env.VUE_APP_GITCOMMIT;
window.$WANRUI.IOCROOTHTML_GITADDRESS = 'https://git.vankeservice.com/ioc/ioc-root-html.git';


singleSpa.registerApplication(
  'layout',
  Promise.resolve(window.layoutApp),
  () => true
);

singleSpa.registerApplication(
  'iocweb',
  loadAppFn(window.GLOBAL_CONFIG.iocwebOrigin, "iocwebApp"),
  location => !/^\/(securitygis|enterprise|overview)/.test(location.pathname)
);

singleSpa.registerApplication(
  'security',
  loadAppFn(window.GLOBAL_CONFIG.securityOrigin, "securityApp"),
  location => location.pathname.startsWith('/securitygis')
);

singleSpa.registerApplication(
  'cockpit',
  loadAppFn(window.GLOBAL_CONFIG.cockpitOrigin, "cockpitApp"),
  location => location.pathname.startsWith('/overview')
);

singleSpa.registerApplication(
  'enterprise',
  loadAppFn(window.GLOBAL_CONFIG.enterpriseOrigin, "enterpriseApp"),
  location => location.pathname.startsWith('/enter-prise')
);

window.addEventListener('single-spa:routing-event', () => {
  singleSpa.getMountedApps();
});

window.singleSpa = singleSpa;
singleSpa.start();


function loadAppFn(appPath, appName) {
  return function () {
    return new Promise((resolve, reject) => {
      var head = document.getElementsByTagName('head')[0];
      var script = document.createElement('script');
      script.type = 'text/javascript';
      script.onload = script.onreadystatechange = () => {
        if (!this.readyState || this.readyState === "loaded" 
                                      || this.readyState === "complete" ) {
          script.onload = script.onreadystatechange = null;
          resolve(window[appName]);
        } else {
          reject("Failed to load module");
        }
      };
      var _v = window[appName + 'Version'] || new Date().getTime();
      script.src = `${appPath}?_v=${_v}`;
      head.appendChild(script);
    })
  }
}