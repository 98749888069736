// const CryptoJS = require("crypto-js");
const CryptoJS = window.CryptoJS;

// 密钥
const SECRET_KEY = "1B609BC66E90319B";

export default {
  /**
   * 加密
   * @param word
   * @returns {*}
   */
  encrypt(word, currentSecret = SECRET_KEY) {
    let key = CryptoJS.enc.Utf8.parse(currentSecret);
    let srcs = CryptoJS.enc.Utf8.parse(word);
    let encrypted = CryptoJS.AES.encrypt(srcs, key, {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  },

  /**
   * 解密
   * @param word
   * @returns {*}
   */
  decrypt(word, currentSecret = SECRET_KEY) {
    if (!word) {
      return "";
    } else {
      let key = CryptoJS.enc.Utf8.parse(currentSecret);
      let decrypt = CryptoJS.AES.decrypt(word, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      });
      return CryptoJS.enc.Utf8.stringify(decrypt).toString();
    }
  }
};
