import { BASEURL } from "./config";
// import axios from "axios";
const axios = window.axios;

const instance = axios.create({});

instance.interceptors.request.use(
  config => {
    if (!("websession" in config.headers)) {
      config.headers["websession"] = sessionStorage.getItem("IOC_WEB_SESSION");
    }
    // obs 接口鉴权
    config.headers["token"] = sessionStorage.getItem("OBS_TOKEN")
      ? sessionStorage.getItem("OBS_TOKEN")
      : "";
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  response => {
    if (response.status === 200) {
      return response.data;
    }

    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default ({ url, method, args = {}, baseURL = BASEURL }) => {
  let config = {
    url,
    method,
    baseURL
  };
  if (method === "post") {
    config.headers = {
      "Content-Type": "application/json"
    };
    config.data = args;
  } else {
    config.params = { ...args };
  }
  return instance.request(config);
};
