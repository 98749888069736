/**
 * cos 云上传
 * @param {Object} cos  cos实例
 * @param {Object} file 需要上传的文件
 * @param {Function} ProgressCallback(speed, percent)
 * @param {String} bucketName 桶名
 * @param {String} region 存储桶所在地域
 * @param {String} fileName 对象名称
 * @param {String} StorageClass 存储类型
 * @param {Function} callBack 回调函数
 */
function cosPutObject({ cos, region, bucketName, fileName, file, StorageClass, ProgressCallback }, callBack) {
  cos.putObject({
    Bucket: bucketName,
    Region: region,
    Key: fileName,
    StorageClass: StorageClass || "STANDARD",
    Body: file, // 上传文件对象
    onProgress: (progressData) => {
      // {"loaded":0,"total":85108,"speed":0,"percent":0}
      ProgressCallback && ProgressCallback(progressData.speed, progressData.percent);
    }
  }, (err, data) => {
    if (err || data.statusCode > 300) {
      callBack({ status: false, data: null });
    } else {
      callBack({ status: true, data });
    }
  });
}

/**
 * cos 获取文件临时链接
 * @param {Object} cos  cos实例
 * @param {String} bucketName 桶名
 * @param {String} region 存储桶所在地域
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 * @param {Number} expires 链接有效时间 官方默认900 秒
 */
function cosGetObjectUrl({cos, bucketName, region, fileName, expires}, callBack) {
  cos.getObjectUrl(
    {
      Bucket: bucketName,
      Region: region,
      Key: fileName,
      Sign: true,
      Expires: expires || 3600
    },
    (err, data) => {
      if (err) {
        callBack({ status: false, data: null });
      } else {
        callBack({ status: true, data: data.Url });
      }
    }
  );
}

/**
 * cos 获取文件临时链接
 * @param {Object} cos  cos实例
 * @param {String} bucketName 桶名
 * @param {String} region 存储桶所在地域
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 */
function cosGetObject({cos, bucketName, region, fileName}, callBack) {
  cos.getObject({
    Bucket: bucketName,
    Region: region,
    Key: fileName
 }, (err, data) => {
   if (err || !data.Body) {
    callBack({ status: false, data: null });
   } else {
    callBack({ status: true, data: data.Body });
   }
 });
}

/**
 * cos 删除文件
 * @param {Object} cos  cos实例
 * @param {String} bucketName 桶名
 * @param {String} region 存储桶所在地域
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 */
function cosDeleteObject({cos, bucketName, region, fileName}, callBack) {
  cos.deleteObject(
    {
      Bucket: bucketName,
      Region: region,
      Key: fileName
    },
    (err, data) => {
      // 如果删除成功或者文件不存在则返回204或200，如果找不到指定的 Bucket，则返回404
      if (err) {
        callBack({ status: false, data: null });
      } else {
        callBack({ status: true, data });
      }
    }
  );
}

/**
 * obs 云上传
 * @param {Object} obs  obs实例
 * @param {Object} file 需要上传的文件
 * @param {Function} ProgressCallback(speed, percent)
 * @param {String} bucketName 桶名
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 */
function obsPutObject({ obs, bucketName, fileName, file, ProgressCallback }, callBack) {
  obs.putObject({
    Bucket: bucketName,
    Key: fileName,
    SourceFile: file,
    ProgressCallback: (transferredAmount, totalAmount, totalSeconds) => {
      // 获取上传平均速率（KB/S）
      let speed = (transferredAmount * 1.0) / totalSeconds / 1024;
      // 获取上传进度百分比
      let percentage = (transferredAmount * 100.0) / totalAmount;
      ProgressCallback && ProgressCallback(speed, percentage);
    }
  },
    (err, result) => {
      if (err || result.CommonMsg.Status >= 300 || !result.InterfaceResult) {
        callBack({ status: false, data: null });
      } else {
        callBack({ status: true, data: result });
      }
    });
}

/**
 * obs 获取文件临时链接
 * @param {Object} obs  obs实例
 * @param {String} bucketName 桶名
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 * @param {Number} saveType 获取后文件类型， file：文件， arraybuffer：二进制数据
 */
function obsGetObjectUrl({ obs, bucketName, fileName, saveType }, callBack) {
  obs.getObject(
    {
      Bucket: bucketName,
      Key: fileName,
      SaveByType: saveType
    },
    (err, result) => {
      if (err || result.CommonMsg.Status >= 300 || !result.InterfaceResult) {
        callBack({ status: false, data: null });
      } else {
        if (saveType === "arraybuffer") {
          // 返回文件的二进制
          callBack({ status: true, data: result.InterfaceResult.Content });
        } else {
          // 返回文件的下载路径
          callBack({ status: true, data: result.InterfaceResult.Content.SignedUrl });
        }
      }
    }
  );
}

/**
 * obs 删除文件
 * @param {Object} obs  obs实例
 * @param {String} bucketName 桶名
 * @param {String} fileName 对象名称
 * @param {Function} callBack 回调函数
 */
function obsDeleteObject({obs, bucketName, fileName}, callBack) {
  obs.deleteObject(
    {
      Bucket: bucketName,
      Key: fileName
    },
    (err, result) => {
      if (err) {
        callBack({ status: false, data: null });
      } else {
        callBack({ status: true, data: result });
      }
    }
  );
}

export {
  cosPutObject,
  obsPutObject,
  cosGetObjectUrl,
  obsGetObjectUrl,
  cosGetObject,
  cosDeleteObject,
  obsDeleteObject
};