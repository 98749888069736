import commonApi from "./commonApi"
import cryptoApi from "./cryptoApi";
import obsApi from "./obsApi"

const GLOBAL_OBJ = window.GLOBAL_OBJ || {};

window.GLOBAL_OBJ = {
  ...GLOBAL_OBJ,
  commonApi,
  cryptoApi,
  obsApi
};